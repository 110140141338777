import React from 'react'

import styles from '@styles/contact.module.scss'

function contact() {
    return (
        <div id='contact' className={styles.section}>
            <div id={styles['info']} className={styles.subSection}>
                <div className={styles.title}>Contact Us<span>!</span></div>
                <div className={styles.body}>If you have any inquiries or questions, feel free to reach out. We're excited to hear from you.</div>
            </div>
            <div className={styles.subSection}>
                <form name='inquiry' id={styles['contactForm']} className={styles.form} method='post' netlify netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="inquiry" />
                    <input type="hidden" name="subject" value="New RRE Inquiry" />
                    <input type="text" name='name' id="contactName" className={styles.input} placeholder='Name' />
                    <input type="email" name='email' id="contactEmail" className={styles.input} placeholder='Email' />
                    <input type="phone" name='phone' id="contactPhone" className={styles.input} placeholder='Phone' />
                    <textarea name='message' id="contactMsg" className={styles.input} placeholder='Message' cols="30" rows="10" />
                    <button type="submit" name='submit' id="contactSubmit" className={`${styles.formButton} ${styles.input}`}>Send</button>
                </form>
            </div>
        </div>
    )
}

export default contact