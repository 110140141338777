import React from 'react'
import styles from '@styles/nav.module.scss'

function nav(props) {
    return (
        <div className={styles.nav} style={{
            opacity: !props.Visibility ? '0' : '1',
            display: !props.Visibility ? 'none' : 'grid',
        }}>
            <span className={styles.logo}>RRE</span>
            <ul className={styles.list}>
                <li className={styles.navLink}> <a href="#content">Who we are</a></li>
                <li className={styles.navLink}> <a href="#contact">Contact us</a></li>
            </ul>
        </div>
    )
}

export default nav