import React from 'react'
import style from '@styles/hero.module.scss'

import heroImg from '@assets/heroImg.jpg'
import chevronDn from '@assets/chevron.png'

function hero(props) {
    return (
        <div id='hero' className={style.section}>
            <div id="leftHero" className={style.sectionLeft} style={{ backgroundImage: `url(${heroImg || props.imgSrc})` }}>
                <div id="heroHeading" className={style.heading}>Rig Rider Express</div>
            </div>
            <div id="rightHero" className={style.sectionRight}>
                <div id="heroSubheading" className={style.subheading}>
                    <div>We are proud<span>.</span></div>
                    <div>We are tough<span>.</span></div>
                    <div>We are truckers<span>.</span></div>
                </div>
            </div>
            <div id='scrollDown' className={style.scrollDown}>
                <img src={chevronDn} alt="" />
            </div>
        </div>
    )
}

export default hero