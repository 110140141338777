import React, { useEffect, useState } from 'react'
import Nav from '@comps/nav'
import Hero from '@comps/hero'
import Content from '@comps/content'
import Contact from '@comps/contact'
import Footer from '@comps/footer'


const App = () => {
    const [NavVis, setNavVis] = useState(true)

    return (
        <div id='container'>
            <Nav Visibility={NavVis} />
            <Hero />
            <Content />
            <Contact />
            <Footer />
        </div>
    )
}

export default App