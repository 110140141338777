import React from 'react'
import styles from '@styles/content.module.scss'

import backgroundImg from '@assets/truck2.webp'

function content() {
    return (
        <div id='content' className={styles.section} style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div id="whoSection" className={styles.subSection}>
                <div id="whoTitle" className={styles.subHeader}>Who We Are</div>
                <div id="whoBody" className={styles.subBody}>We are a premium transportation and hauling company with decades of experience and expertise behind the wheel.</div>
            </div>
            <div id="whatSection" className={styles.subSection}>
                <div id="whatTitle" className={styles.subHeader}>What We Do</div>
                <div id="whatBody" className={styles.subBody}>We load, transport, and deliver equipment and goods as efficiently as possible.</div>
            </div>
            <div id="howSection" className={styles.subSection}>
                <div id="howTitle" className={styles.subHeader}>How We Do It</div>
                <div id="howBody" className={styles.subBody}>Our drivers provide the experience and human touch. Our trucks deliver reliability. Our trailers give you options.</div>
            </div>
        </div>
    )
}

export default content